//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dayjs from 'dayjs';
import ViuBox from 'viu/components/viu-box/viu-box.vue';
import ViuModal from 'viu/components/viu-modal/viu-modal.vue';
import ViuButton from 'viu/components/viu-button/viu-button.vue';
import StepLayout from './step-layout.vue';
import PowerBox from '../components/power-box.vue';
import stepMixin from '../lib/step-mixin.js';
import { changeElectricalPower } from '../lib/api.js';
import CopiarOferta from './copiar-oferta.vue';
import IconoDescargarPdf from '../../../../resources/images/icon-descargar-pdf.svg';
import DetalleOferta from './detalle-oferta.vue';

export default {
  components: {
    ViuBox,
    ViuModal,
    ViuButton,
    PowerBox,
    StepLayout,
    CopiarOferta,
    DetalleOferta
  },
  mixins: [stepMixin],
  props: {
    offers: Object,
    leadId: String,
    channel: String,
    selectedRates: Array,
    allSteps: Object
  },
  data: () => ({
    selectedOffer: '',
    loading: false,
    downloading: false,
    potenciaContratada: null,
    tarifaAtr: null,
    modalCopiarOferta: false,
    IconoDescargarPdf,
    enlace: '',
    price: ''
  }),
  computed: {
    offersList() {
      return this.offers?.external?.ofertas || [];
    },
    selectedOfferObj() {
      return this.selectedRates.find(offer => offer.id === this.selectedOffer);
    },
    hiredPower() {
      if (this.selectedOfferObj === undefined) return '';

      const power = Object.entries(this.selectedOfferObj.hiredPower);

      const list = this.selectedOfferObj.values.powerMargin.totalPeriods
        .map((element, index) => {
          return `P${index + 1}: ${power[index][1] / 1000} kW`;
        })
        .join(', ');

      return list;
    }
  },
  created() {
    if (this.allSteps[5].finished) {
      this.selectedOffer = this.allSteps[5].inputData.offerSelected.id;
    }
    this.enlace = `${window.location.protocol}//${window.location.hostname}${this.$route.path}`;
  },
  methods: {
    handleDownload() {
      window.print();
    },
    share() {
      const aux = document.createElement('input');
      aux.setAttribute('value', window.location.href);
      document.body.appendChild(aux);
      aux.select();
      document.execCommand('copy');
      document.body.removeChild(aux);
      this.$notify.success('Enlace copiado al portapapeles');
      // this.modalCopiarOferta = true;
    },
    closeModalCopiarOferta(type) {
      if (type === 'icon') {
        this.modalCopiarOferta = false;
      }
    },
    onOfferSelected(id) {
      this.selectedOffer = id;
    },
    isAgent() {
      const channel = window.localStorage.getItem('channel');

      return channel === this.channel;
    },
    isAutomaticChannel() {
      const bool = this.channel === 'automatic';
      return bool;
    },
    onContinue() {
      this.loading = true;
      this.$emit('continue', {
        offerSelected: {
          ahorroMensaul: '',
          aliasTarifa: this.selectedOfferObj.name,
          autoconsumo: '',
          comercializadora: this.selectedOfferObj.energyMarketerName,
          energia: {
            precio: {
              p1: this.selectedOfferObj.values.feesEnergy.price.p1,
              p2: this.selectedOfferObj.values.feesEnergy.price.p2,
              p3: this.selectedOfferObj.values.feesEnergy.price.p3,
              p4: this.selectedOfferObj.values.feesEnergy.price.p4,
              p5: this.selectedOfferObj.values.feesEnergy.price.p5,
              p6: this.selectedOfferObj.values.feesEnergy.price.p6
            }
          },
          id: this.selectedOfferObj.id,
          idTarifaPersonalizada: this.selectedOfferObj.id,
          fecha: dayjs().format('YYYY-MM-DD'),
          nombreTarifa: this.selectedOfferObj.name,
          potencia: {
            precio: {
              p1: this.selectedOfferObj.values.powerMargin.price.p1,
              p2: this.selectedOfferObj.values.powerMargin.price.p2,
              p3: this.selectedOfferObj.values.powerMargin.price.p3,
              p4: this.selectedOfferObj.values.powerMargin.price.p4,
              p5: this.selectedOfferObj.values.powerMargin.price.p5,
              p6: this.selectedOfferObj.values.powerMargin.price.p6
            }
          },
          precioEnergia: {
            p1: this.selectedOfferObj.values.feesEnergy.price.p1,
            p2: this.selectedOfferObj.values.feesEnergy.price.p2,
            p3: this.selectedOfferObj.values.feesEnergy.price.p3,
            p4: this.selectedOfferObj.values.feesEnergy.price.p4,
            p5: this.selectedOfferObj.values.feesEnergy.price.p5,
            p6: this.selectedOfferObj.values.feesEnergy.price.p6
          },
          precioMensual: this.price,
          precioPotencias: {
            p1: this.selectedOfferObj.values.powerMargin.price.p1,
            p2: this.selectedOfferObj.values.powerMargin.price.p2,
            p3: this.selectedOfferObj.values.powerMargin.price.p3,
            p4: this.selectedOfferObj.values.powerMargin.price.p4,
            p5: this.selectedOfferObj.values.powerMargin.price.p5,
            p6: this.selectedOfferObj.values.powerMargin.price.p6
          },
          tipoTarifa: this.selectedOfferObj.type,
          feeEnergiaSelected: {
            p1: this.selectedOfferObj.values.feesEnergy.selected.p1,
            p2: this.selectedOfferObj.values.feesEnergy.selected.p2,
            p3: this.selectedOfferObj.values.feesEnergy.selected.p3,
            p4: this.selectedOfferObj.values.feesEnergy.selected.p4,
            p5: this.selectedOfferObj.values.feesEnergy.selected.p5,
            p6: this.selectedOfferObj.values.feesEnergy.selected.p6
          },
          feePotenciaSelected: {
            p1: this.selectedOfferObj.values.powerMargin.selected.p1,
            p2: this.selectedOfferObj.values.powerMargin.selected.p2,
            p3: this.selectedOfferObj.values.powerMargin.selected.p3,
            p4: this.selectedOfferObj.values.powerMargin.selected.p4,
            p5: this.selectedOfferObj.values.powerMargin.selected.p5,
            p6: this.selectedOfferObj.values.powerMargin.selected.p6
          },
          feeAutoconsumoSelected: {
            p1: this.selectedOfferObj.values.selfConsumption.selected.p1,
            p2: this.selectedOfferObj.values.selfConsumption.selected.p2,
            p3: this.selectedOfferObj.values.selfConsumption.selected.p3,
            p4: this.selectedOfferObj.values.selfConsumption.selected.p4,
            p5: this.selectedOfferObj.values.selfConsumption.selected.p5,
            p6: this.selectedOfferObj.values.selfConsumption.selected.p6
          },
          scoring: this.selectedOfferObj.scoring
        },
        externalData: {
          datosEstudios: {
            potenciaContratada: {
              p1:
                this.selectedOfferObj.values.powerMargin.totalPeriods.length > 0
                  ? this.selectedOfferObj.hiredPower.p1
                  : '',
              p2:
                this.selectedOfferObj.values.powerMargin.totalPeriods.length > 1
                  ? this.selectedOfferObj.hiredPower.p2
                  : '',
              p3:
                this.selectedOfferObj.values.powerMargin.totalPeriods.length > 2
                  ? this.selectedOfferObj.hiredPower.p3
                  : '',
              p4:
                this.selectedOfferObj.values.powerMargin.totalPeriods.length > 3
                  ? this.selectedOfferObj.hiredPower.p4
                  : '',
              p5:
                this.selectedOfferObj.values.powerMargin.totalPeriods.length > 4
                  ? this.selectedOfferObj.hiredPower.p5
                  : '',
              p6:
                this.selectedOfferObj.values.powerMargin.totalPeriods.length > 5
                  ? this.selectedOfferObj.hiredPower.p6
                  : ''
            }
          }
        }
      });
    },
    async cambiarPotencias(potencias) {
      try {
        await changeElectricalPower(this.leadId, potencias);
        window.location.reload();
      } catch (error) {
        this.$notify.error('Ha ocurrido un error, contacta con el administrador.');
      }
    }
  }
};
