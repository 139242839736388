//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ViuCheckBox from 'viu/components/viu-checkbox/viu-checkbox.vue';
import ViuExpandable from 'viu/components/viu-expandable/viu-expandable.vue';
import ViuBox from 'viu/components/viu-box/viu-box.vue';
import ViuHeader from 'viu/components/viu-header/viu-header.vue';
import ViuSwitch from 'viu/components/viu-switch/viu-switch.vue';
import ViuButton from 'viu/components/viu-button/viu-button.vue';
import ViuModal from 'viu/components/viu-modal/viu-modal.vue';
import stepMixin from '../lib/step-mixin.js';
import StepLayout from './step-layout.vue';
import SliderComponente from './slider-componente.vue';
import { createRateV3 } from './RateV3.js';
import DetalleTarifa from './detalle-tarifa.vue';
import validateForm from '$lib/validate-form.js';
import ModalScoring from './modal-scoring.vue';
import SliderComponenteBateriaVirtual from './slider-componente-bateria-virtual.vue';
// import CalculoAhorro from './calculo-ahorro.vue';
import * as api from '../lib/api.js';
import * as apis from '$network/api.js';

import PuntosSuministrosFormulario from './puntos-suministros-formulario.vue';

const { VUE_APP_EDITAR_CONSUMO_ANUAL_ESTIMADO } = process.env;

export default {
  components: {
    StepLayout,
    ViuCheckBox,
    ViuExpandable,
    ViuBox,
    ViuModal,
    ViuHeader,
    ViuSwitch,
    ViuButton,
    SliderComponente,
    PuntosSuministrosFormulario,
    DetalleTarifa,
    SliderComponenteBateriaVirtual,
    ModalScoring
    // CalculoAhorro
  },
  mixins: [stepMixin],
  props: {
    rates: {
      type: Array,
      required: true
    },
    selectedRates: {
      type: Array,
      required: false,
      default: () => []
    },
    tarifaAtr: {
      type: String,
      required: true
    },
    allSteps: {
      type: Object
    },
    leadId: {
      type: String
    }
  },
  data: () => ({
    loading: false,
    bateria: 0, // Solo pruebas
    editarConsumoAnualEstimado: false,
    myRates: [],
    idsScoring: [],
    problem: '',
    steps: {},
    puntoSuministro: {},
    tarifaAtrUsuario: '',
    sendingScoring: false,
    powersStates: [
      { value: 'grouped', text: 'Agrupados' },
      { value: 'individual', text: 'Individuales' }
    ],
    optionsTarifas: [
      { value: 'all', text: 'Todas' },
      { value: '018', text: '2.0TD' },
      { value: '019', text: '3.0TD' },
      { value: '020', text: '6.1TD' },
      { value: '021', text: '6.2TD' },
      { value: '022', text: '6.3TD' },
      { value: '023', text: '6.4TD' }
    ],
    tarifaSelect: 'all',
    openFeeEenrgia: false,
    openMargenPotencia: false,
    openFeeAutoconsumo: false,
    openBateriaVirtalTarifa: false,
    disabledFeeEnergy: {},
    disabledFeePotencia: {},
    disabledFeeAutoconsumo: {},
    idChanged: [],
    scoringActivo: false,
    staticData: {
      optionsTipoGestion: [
        { value: 'cambio_comercializadora', text: 'Cambio comercializador' },
        { value: 'alta_nueva', text: 'Alta nueva' }
      ],
      optionsCNAE: [],
      optionsTipoFianza: [
        {
          value: 1,
          text: 'Depósito en efectivo'
        },
        {
          value: 2,
          text: 'Prepago'
        },
        {
          value: 3,
          text: 'Aval bancario'
        },
        {
          value: 4,
          text: 'PCG'
        },
        {
          value: 5,
          text: 'Seguro de crédito'
        }
      ],
      optionsInicioPeriodo: [
        {
          value: 'periodo_desestimiento',
          text: 'Tras el periodo de desistimiento'
        },
        {
          value: 'fecha_fija',
          text: 'Fecha fija'
        },
        {
          value: 'cuanto_antes',
          text: 'Cuanto antes'
        }
      ],
      optionsTipoAutoconsumo: [
        { value: 'individual', text: 'Individual' },
        { value: 'colectivo', text: 'Colectivo' }
      ],
      optionsExcedentes: [
        { value: 'sin_compensacion_excedentes', text: 'Sin compensación por excedentes' },
        { value: 'con_compensacion_excedentes', text: 'Con compensación por excedentes' }
      ],
      optionsAutoconsumoTipoGestion: [
        { value: 'ya_dispone_en_suministro', text: 'Ya dispone en Suministro' },
        { value: 'solicitar_nueva_alta', text: 'Solicitar nueva alta' }
      ],
      optionsAutoconsumoExcedentes: [
        { value: 'con_excedentes', text: 'Con excedentes' },
        { value: 'sin_excedentes', text: 'Sin excedentes' }
      ],
      optionsAutoconsumoCompensacionExcedentes: [
        { value: 'con_compensación', text: 'Con compensación' },
        { value: 'sin_compensación', text: 'Sin compensación' }
      ],
      optionsGestionBateriaVirtual: [
        { value: '1', text: 'Crear nueva Batería Virtual' },
        { value: '2', text: 'Importar Batería Virtual existente' }
      ],
      optionsBateria: [
        { value: 'U', text: 'Batería única' },
        { value: 'M', text: 'Multibatería' }
      ],
      optionsAutoconsumoTipoCNMC: [
        { value: '00', text: '00 - Sin Autoconsumo' },
        { value: '01', text: '01 - Autoconsumo Tipo 1' },
        { value: '2A', text: '2A - Autoconsumo tipo 2 (según el Art. 13. 2. a) RD 900/2015)' },
        { value: '2B', text: '2B - Autoconsumo tipo 2 (según el Art. 13. 2. b) RD 900/2015)' },
        {
          value: '2G',
          text: '2G - Servicios auxiliares de una generación ligada a un autoconsumo tipo 2'
        },
        {
          value: '31',
          text: '31 - Sin Excedentes Individual – Consumo'
        },
        {
          value: '32',
          text: '32 - Sin Excedentes Colectivo – Consumo'
        },
        {
          value: '33',
          text: '33 - Sin Excedentes Colectivo con acuerdo de compensación – Consumo'
        },
        {
          value: '41',
          text: '41 - Con excedentes y compensación Individual - Consumo'
        },
        {
          value: '42',
          text: '42 - Con excedentes y compensación Colectivo– Consumo'
        },
        {
          value: '51',
          text:
            '51 - Con excedentes sin compensación Individual sin cto de SSAA en Red Interior– Consumo'
        },
        {
          value: '52',
          text:
            '52 - Con excedentes sin compensación Colectivo sin cto de SSAA en Red Interior– Consumo'
        },
        {
          value: '53',
          text:
            '53 - Con excedentes sin compensación Individual con cto SSAA en Red Interior– Consumo'
        },
        {
          value: '54',
          text: '54 - Con excedentes sin compensación individual con cto SSAA en Red Interior– SSAA'
        },
        {
          value: '55',
          text:
            '55 - Con excedentes sin compensación Colectivo con cto de SSAA en Red Interior– Consumo'
        },
        {
          value: '56',
          text:
            '56 - Con excedentes sin compensación Colectivo con cto de SSAA en Red Interior– SSAA'
        },
        {
          value: '61',
          text:
            '61 - Con excedentes sin compensación Individual con cto SSAA a través de red – Consumo'
        },
        {
          value: '62',
          text:
            '62 - Con excedentes sin compensación individual con cto SSAA a través de red – SSAA'
        },
        {
          value: '63',
          text:
            '63 - Con excedentes sin compensación Colectivo con cto de SSAA a través de red – Consumo'
        },
        {
          value: '64',
          text:
            '64 - Con excedentes sin compensación Colectivo  con cto de SSAA a través de red – SSAA'
        },
        {
          value: '71',
          text:
            '71 - Con excedentes sin compensación Individual con cto SSAA a través de red y red interior – Consumo'
        },
        {
          value: '72',
          text:
            '72 - Con excedentes sin compensación individual con cto SSAA a través de red y red interior – SSAA'
        },
        {
          value: '73',
          text:
            '73 - Con excedentes sin compensación Colectivo con cto de SSAA  a través de red y red interior – Consumo'
        },
        {
          value: '74',
          text:
            '74 - Con excedentes sin compensación Colectivo con cto de SSAA a través de red y red interior - SSAA'
        },
        {
          value: '43',
          text: '43 - Con excedentes y compensación Colectivo a través de red– Consumo'
        }
      ],
      optionsPeriodoComision: [{ value: '1', text: '€/día' }],
      optionsModoActivacion: [
        { value: '1', text: 'Fecha determinada' },
        { value: '2', text: 'En activar autoconsumo' },
        { value: '3', text: 'Inmediatamente' }
      ],
      optionsClasificacion: [
        { value: 'U', text: 'Batería única' },
        { value: 'M', text: 'Batería Virtual de tipo 2' }
      ]
    }
  }),
  computed: {
    allRatesSelected() {
      const arrayRates = this.myRates.filter(
        rate => rate.tarifaAtr === this.tarifaSelect || this.tarifaSelect === 'all'
      );

      if (arrayRates.length === 0) return false;

      return arrayRates.every(
        rate =>
          rate.selected && (rate.tarifaAtr === this.tarifaSelect || this.tarifaSelect === 'all')
      );
    },
    ratesSelectedCount() {
      return this.myRates.filter(rate => rate.selected).length;
    }
  },

  created() {
    this.loading = true;

    if (
      VUE_APP_EDITAR_CONSUMO_ANUAL_ESTIMADO === 'false' ||
      VUE_APP_EDITAR_CONSUMO_ANUAL_ESTIMADO === undefined
    ) {
      this.editarConsumoAnualEstimado = false;
    }

    if (VUE_APP_EDITAR_CONSUMO_ANUAL_ESTIMADO === 'true') {
      this.editarConsumoAnualEstimado = true;
    }

    apis.scoringActivo().then(response => {
      this.scoringActivo = response.data;
      this.loading = false;
    });

    this.myRates = this.rates.map(rate => {
      return createRateV3(rate);
    });

    this.myRates.forEach((rate, index) => {
      // eslint-disable-next-line no-shadow
      const selectedRate = this.selectedRates.find(selectedRate => selectedRate.id === rate.id);
      if (selectedRate) {
        this.myRates[index].selected = true;
      }
      if (rate.hiredPower === null) {
        this.myRates[index].hiredPower = {
          p1: '',
          p2: '',
          p3: '',
          p4: '',
          p5: '',
          p6: ''
        };
      }
    });
    this.steps = JSON.parse(JSON.stringify(this.allSteps));
    this.tarifaAtrUsuario = this.checkUndefined(this.steps[2]?.externalData?.tarifaAtr);

    this.tarifaSelect =
      this.checkUndefined(this.allSteps[2].externalData.tarifaAtr) !== '' &&
      this.optionsTarifas.some(option => option.value === this.allSteps[2].externalData.tarifaAtr)
        ? this.allSteps[2].externalData.tarifaAtr
        : 'all';

    this.getStaticData();

    if (this.allSteps['4'].finished) {
      this.myRates.forEach((element, index) => {
        this.allSteps['4'].inputData.selectedRates.forEach(rate => {
          if (rate.id === element.id) {
            this.idChanged.push(element.id);
            this.myRates[index].hiredPower = rate.hiredPower;
            this.myRates[index].values = rate.values;
            this.myRates[index].bateriaVirtualValues = this.allSteps['4'].inputData.bateriaVirtual;
          }
        });
      });
    }
  },
  methods: {
    readOnlySwitch(value, type, original) {
      // Extraer todos los valores de value.max
      const maxValues = Object.values(value.max);
      // Comprobar si todos los valores son iguales
      const allEqualMax = maxValues.every((val, _, arr) => val === arr[0]);

      // Extraer todos los valores de value.max
      const minValues = Object.values(value.min);
      // Comprobar si todos los valores son iguales
      const allEqualMin = minValues.every((val, _, arr) => val === arr[0]);

      if (allEqualMin && allEqualMax && type === 'grouped' && original === 'individual')
        return false;

      if (allEqualMin && allEqualMax && type === 'individual') return false;

      return true;
    },
    requiredEnergia(id) {
      let required = false;
      this.myRates.forEach(item => {
        if (item.states.feesEnergy === 'individual' && item.id === id) {
          // Verificar si al menos uno de los períodos no es null
          required = item.values.feesEnergy.totalPeriods.some(
            period => this.checkUndefined(item.values.feesEnergy.selected[period]) !== ''
          );
        }
      });
      return required;
    },
    requiredPotencias(id) {
      let required = false;
      this.myRates.forEach(item => {
        if (item.id === id) {
          // Verificar si al menos uno de los períodos no es null
          required = item.values.powerMargin.totalPeriods.some(
            period => this.checkUndefined(item.values.powerMargin.selected[period]) !== ''
          );
        }
      });
      return required;
    },
    requiredAutoconsumo(id) {
      let required = false;
      this.myRates.forEach(item => {
        if (item.states.selfConsumption === 'individual' && item.id === id) {
          // Verificar si al menos uno de los períodos no es null
          required = item.values.selfConsumption.totalPeriods.some(
            period => this.checkUndefined(item.values.selfConsumption.selected[period]) !== ''
          );
        }
      });
      return required;
    },
    scoringDenegado() {
      return this.myRates.some(
        rate =>
          (this.computedScoring(rate.id) === 'Denegado' ||
            this.computedScoring(rate.id) === 'No calculado') &&
          rate.selected
      );
    },
    computedScoring(id) {
      if (!this.scoringActivo) return 'Desactivado';
      if (id in this.idsScoring) {
        if (this.idsScoring[id] === 'manual') {
          return 'Aprobación manual';
        }

        return this.idsScoring[id].charAt(0).toUpperCase() + this.idsScoring[id].slice(1);
      }
      return 'No calculado';
    },
    checkNumber(value) {
      if (value === undefined || value === null || value === '') return 0;
      return value;
    },
    async calcularScoring() {
      if (this.sendingScoring) return;

      if (!this.validateHiredPower()) return;

      let result = 0;

      const childPuntosSuministrosFormulario = validateForm.checkChildren(
        'PuntosSuministrosFormulario',
        this
      );

      if (childPuntosSuministrosFormulario !== null) {
        result += childPuntosSuministrosFormulario.validate();
        if (result !== 0) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.$el.getElementsByClassName('invalid')[0].scrollIntoView();
            }, 500);
          });
          return;
        }
      }

      if (
        this.checkUndefined(this.puntoSuministro.consumo_anual_estimado) === '' &&
        this.editarConsumoAnualEstimado
      ) {
        this.$notify.error('Debe introducir un consumo anual estimado para calcular el scoring.');
        return;
      }
      if (this.checkUndefined(this.puntoSuministro.cups) === '') {
        this.$notify.error('Debe introducir un CUPS para calcular el scoring.');
        return;
      }

      if (this.checkUndefined(this.puntoSuministro.identificador) === '') {
        this.$notify.error('Debe introducir un documento para calcular el scoring.');
        return;
      }

      const allEnergyFee = this.myRates
        .filter(rate => rate.selected)
        .map(rate => rate.values.feesEnergy.selected);

      const allEnergyNumber = allEnergyFee.map(element => Object.values(element));

      let maxEnergyFee = Math.max(...allEnergyNumber.flat().filter(Number));

      if (this.checkUndefined(maxEnergyFee) === '' || maxEnergyFee === -Infinity) {
        maxEnergyFee = 0;
      }

      const allMaxPower = this.myRates.filter(rate => rate.selected).map(rate => rate.hiredPower);

      const allPower = allMaxPower.map(element => Object.values(element));

      const maxPower = Math.max(...allPower.flat().filter(Number));

      if (this.checkUndefined(maxPower) === '' || maxPower === -Infinity) {
        this.$notify.error('Debe introducir un Margen en Potencia para calcular el scoring.');
        return;
      }

      const rateIds = [];

      this.myRates.forEach(rate => {
        if (rate.selected) {
          rateIds.push({
            id_tarifa: rate.id,
            potencia_maxima: Math.max(...Object.values(rate.hiredPower).filter(Number)),
            fee_energia_maximo:
              Math.max(...Object.values(rate.values.feesEnergy.selected).filter(Number)) ===
              -Infinity
                ? 0
                : Math.max(...Object.values(rate.values.feesEnergy.selected).filter(Number)) / 1000
          });
        }
      });

      this.sendingScoring = true;

      const data = {
        cups: this.puntoSuministro.cups,
        rateIds,
        documentNumber: this.puntoSuministro.identificador,
        anualVolumen: this.puntoSuministro.consumo_anual_estimado,
        fechaNacimiento: this.puntoSuministro.fecha_nacimiento,
        codPostal: this.puntoSuministro.CodigoPostal,
        autonomo: this.puntoSuministro.autonomo,
        tipoIdentificador: this.puntoSuministro.tipo_identificador,
        leadId: this.leadId
      };
      await apis
        .calcularScoring(data)
        .then(response => {
          if ('status_scoring' in response.data) {
            if (response.data.status_scoring === 'desactivado') {
              this.sendingScoring = false;
              return;
            }
            this.idsScoring = response.data.status_scoring;
            this.computedScoring();
            this.sendingScoring = false;
          }
        })
        .catch(() => {
          this.sendingScoring = false;
          this.$notify.error('No se ha podido calcular el scoring de las tarifas seleccionadas.');
        });
    },
    checkUndefined(texto) {
      if (texto !== undefined && texto !== null) return texto;
      return '';
    },
    // comparedHiredPower(index) {
    //   return (
    //     this.checkUndefined(this.allSteps[3].externalData?.tarifas[index]?.hiredPower?.p1) !==
    //     this.checkUndefined(this.allSteps[3].externalData?.tarifas[index]?.hiredPower?.p2)
    //   );
    // },
    validateFeesEnergy() {
      // Fee Energía
      let error = 0;
      this.myRates.forEach(item => {
        if (item.selected) {
          // Verificar si al menos uno de los períodos no es null
          const atLeastOneNotNull = item.values.feesEnergy.totalPeriods.some(
            period => this.checkUndefined(item.values.feesEnergy.selected[period]) !== ''
          );

          if (!atLeastOneNotNull) {
            error = 0;
            return;
          }

          if (atLeastOneNotNull) {
            // Si al menos uno no es null, verificar que todos estén rellenados
            const allFilled = item.values.feesEnergy.totalPeriods.every(period => {
              const value = item.values.feesEnergy.selected[period];
              return this.checkUndefined(value) !== '';
            });

            if (!allFilled) {
              this.myRates.forEach(rate => {
                // eslint-disable-next-line no-param-reassign
                rate.expandible = false;
              });
              this.openFeeEenrgia = false;
              item.values.feesEnergy.totalPeriods.forEach(period => {
                if (this.checkUndefined(item.values.feesEnergy.selected[period]) === '')
                  this.$refs[`energia_${item.id}_${period}`][0].$refs.fee.invalidStored = true;
              });

              this.myRates.find(rate => rate.id === item.id).expandible = true;
              this.$nextTick(() => {
                this.openFeeEenrgia = true;
                setTimeout(() => {
                  this.$el.getElementsByClassName('invalid')[0].scrollIntoView({ block: 'center' });
                }, 400);
              });
              this.$notify.error(
                `Debe rellenar todos los campos de Fee Energía para la tarifa ${item.alias}`
              );
              error = 1;
            }
          }
        }
      });
      return error;
    },
    validateFeesPotencia() {
      // Fee Potencia
      let error = 0;
      this.myRates.forEach(item => {
        if (item.selected) {
          // Verificar si al menos uno de los períodos no es null
          const atLeastOneNotNull = item.values.powerMargin.totalPeriods.some(
            period => this.checkUndefined(item.values.powerMargin.selected[period]) !== ''
          );

          if (!atLeastOneNotNull) {
            error = 0;
            return;
          }

          if (atLeastOneNotNull) {
            // Si al menos uno no es null, verificar que todos estén rellenados
            const allFilled = item.values.powerMargin.totalPeriods.every(period => {
              const value = item.values.powerMargin.selected[period];
              return this.checkUndefined(value) !== '';
            });

            if (!allFilled) {
              this.myRates.forEach(rate => {
                // eslint-disable-next-line no-param-reassign
                rate.expandible = false;
              });
              this.openMargenPotencia = false;
              item.values.powerMargin.totalPeriods.forEach(period => {
                if (this.checkUndefined(item.values.powerMargin.selected[period]) === '')
                  this.$refs[`slider_${item.id}_${period}`][0].$refs.fee.invalidStored = true;
              });
              this.myRates.find(rate => rate.id === item.id).expandible = true;
              this.$nextTick(() => {
                this.openMargenPotencia = true;
                setTimeout(() => {
                  this.$el.getElementsByClassName('invalid')[0].scrollIntoView({ block: 'center' });
                }, 400);
              });
              this.$notify.error(
                `Debe rellenar todos los campos de Fee en el margen de potencia para la tarifa ${item.alias}`
              );
              error = 1;
            }
          }
        }
      });
      return error;
    },
    validateFeesAutoconsumo() {
      // Fee Autoconsumo
      let error = 0;
      this.myRates.forEach(item => {
        if (item.selected) {
          // Verificar si al menos uno de los períodos no es null
          const atLeastOneNotNull = item.values.selfConsumption.totalPeriods.some(
            period => this.checkUndefined(item.values.selfConsumption.selected[period]) !== ''
          );

          if (!atLeastOneNotNull) {
            error = 0;
            return;
          }

          if (atLeastOneNotNull) {
            // Si al menos uno no es null, verificar que todos estén rellenados
            const allFilled = item.values.selfConsumption.totalPeriods.every(period => {
              const value = item.values.selfConsumption.selected[period];
              return this.checkUndefined(value) !== '';
            });

            if (!allFilled) {
              this.myRates.forEach(rate => {
                // eslint-disable-next-line no-param-reassign
                rate.expandible = false;
              });

              this.openFeeAutoconsumo = false;
              item.values.selfConsumption.totalPeriods.forEach(period => {
                if (this.checkUndefined(item.values.selfConsumption.selected[period]) === '')
                  this.$refs[`autoconsumo_${item.id}_${period}`][0].$refs.fee.invalidStored = true;
              });
              this.myRates.find(rate => rate.id === item.id).expandible = true;
              this.$nextTick(() => {
                this.openFeeAutoconsumo = true;
                setTimeout(() => {
                  this.$el.getElementsByClassName('invalid')[0].scrollIntoView({ block: 'center' });
                }, 400);
              });
              this.$notify.error(
                `Debe rellenar todos los campos de Fee en el autoconsumo para la tarifa ${item.alias}`
              );
              error = 1;
            }
          }
        }
      });
      return error;
    },
    getPeriodHiredpower(index, period) {
      if (this.steps[3].externalData?.tarifas[index]?.hiredPower === null) return '';
      return this.checkUndefined(this.steps[3].externalData?.tarifas[index]?.hiredPower[period]);
    },
    async onContinue() {
      let result = 0;

      const childPuntosSuministrosFormulario = validateForm.checkChildren(
        'PuntosSuministrosFormulario',
        this
      );

      if (childPuntosSuministrosFormulario !== null) {
        result += childPuntosSuministrosFormulario.validate();
        if (result !== 0) {
          this.$nextTick(() => {
            setTimeout(() => {
              this.$el.getElementsByClassName('invalid')[0].scrollIntoView();
            }, 500);
          });
        }
      }

      if (this.scoringActivo) {
        await this.calcularScoring();

        if (this.scoringDenegado()) {
          this.$notify.error(
            'No se puede continuar al siguiente paso si alguna de las tarifas seleccionadas tiene el Scoring denegado.'
          );
          return;
        }
      }

      if (
        this.validateHiredPower() &&
        this.validateFeesEnergy() === 0 &&
        this.validateFeesPotencia() === 0 &&
        this.validateFeesAutoconsumo() === 0 &&
        result === 0
      ) {
        this.loading = true;

        const garantia = {
          tipo_garantia: this.puntoSuministro.tipo_fianza,
          importe_garantia: this.puntoSuministro.importe_fianza
        };

        const formData = new FormData();

        this.myRates.forEach(elements => {
          const rateWithScoring = {
            ...elements,
            scoring: this.computedScoring(elements.id)
          };
          formData.append('selectedRates[]', JSON.stringify(rateWithScoring));
        });

        const opcionesBateriaVirtual = {
          tipo_gestion_bateria_virtual:
            this.puntoSuministro.bateriaVirtual.tipo_gestion_bateria_virtual ?? '',
          modo_bateria: this.puntoSuministro.bateriaVirtual.modo_bateria ?? '',
          alfa: this.puntoSuministro.bateriaVirtual.alfa ?? 0,
          modo_activacion: this.puntoSuministro.bateriaVirtual.modo_activacion ?? '',
          fecha: this.puntoSuministro.bateriaVirtual.fecha ?? '',
          codigo_bateria_virtual: this.puntoSuministro.bateriaVirtual.codigo_bateria_virtual ?? '',
          identificador_asociado: this.puntoSuministro.bateriaVirtual.identificador_asociado ?? ''
        };

        const tempAutoconsumo = {
          compensacion_por_excedentes: this.checkUndefined(
            this.puntoSuministro.autoconsumo?.compensacion_por_excedentes
          ),
          excedentes: this.checkUndefined(this.puntoSuministro.autoconsumo?.excedentes),
          tipo_autoconsumo: this.checkUndefined(this.puntoSuministro.autoconsumo?.tipo_autoconsumo),
          tipo_autoconsumo_select: this.checkUndefined(
            this.puntoSuministro.autoconsumo?.tipo_autoconsumo_select
          ),
          tipo_gestion: this.checkUndefined(this.puntoSuministro.autoconsumo?.tipo_gestion)
        };

        const tempDatosTitular = {
          tipoPersona: this.checkUndefined(this.puntoSuministro.tipo_persona),
          tipoIdentificador: this.checkUndefined(this.puntoSuministro.tipo_identificador),
          identificador: this.checkUndefined(this.puntoSuministro.identificador),
          nombre: this.checkUndefined(this.puntoSuministro.nombre_titular),
          primerApellido: this.checkUndefined(this.puntoSuministro.primer_apellido_titular),
          segundoApellido: this.checkUndefined(this.puntoSuministro.segundo_apellido_titular),
          razonSocial: this.checkUndefined(this.puntoSuministro.razon_social)
        };

        formData.append('codigo_cnae', this.puntoSuministro.CNAE);
        formData.append('datosTitular', JSON.stringify(tempDatosTitular));
        // formData.append('prorroga_tacita', this.puntoSuministro.prorroga_tacita === 'S');
        formData.append('consumo_anual_estimado', this.puntoSuministro.consumo_anual_estimado);
        // formData.append('bateria_virtual', JSON.stringify(opcionesBateriaVirtual));
        formData.append('garantia', JSON.stringify(garantia));
        formData.append('inicio_contrato', this.puntoSuministro.inicio_contrato);
        formData.append('fecha_inicio_contrato', this.puntoSuministro.fecha_inicio_contrato);
        formData.append('dado_alta_esta_comer', this.puntoSuministro.dado_alta_esta_comer);
        formData.append('mismo_titular', this.puntoSuministro.mismo_titular);
        formData.append('misma_potencia', this.puntoSuministro.misma_potencia);
        formData.append('autoconsumo', JSON.stringify(tempAutoconsumo));
        formData.append('id_cnae', this.puntoSuministro.idCnae);
        formData.append('autonomo', this.puntoSuministro.autonomo === 'S');
        formData.append('fecha_nacimiento', this.puntoSuministro.fecha_nacimiento);
        formData.append('cod_postal', this.puntoSuministro.CodigoPostal);

        if (this.puntoSuministro.fichero.length > 0) {
          this.puntoSuministro.fichero.forEach(fichero => {
            formData.append('documentacion_adicional[]', fichero);
          });
        }

        if (this.puntoSuministro.newFichero.length > 0) {
          this.puntoSuministro.newFichero.forEach(fichero => {
            formData.append('new_documentacion_adicional[]', fichero);
          });
        }

        if (this.puntoSuministro.oldFichero.length > 0) {
          this.puntoSuministro.oldFichero.forEach(fichero => {
            formData.append('old_documentacion_adicional[]', JSON.stringify(fichero));
          });
        }

        if (this.puntoSuministro.anexos.length > 0) {
          this.puntoSuministro.anexos.forEach(anexos => {
            formData.append('documentacion_anexos[]', anexos);
          });
        }

        if (this.puntoSuministro.newAnexos.length > 0) {
          this.puntoSuministro.newAnexos.forEach(anexos => {
            formData.append('new_documentacion_anexos[]', anexos);
          });
        }

        if (this.puntoSuministro.oldAnexos.length > 0) {
          this.puntoSuministro.oldAnexos.forEach(anexos => {
            formData.append('old_documentacion_anexos[]', JSON.stringify(anexos));
          });
        }

        if (
          Object.keys(this.puntoSuministro.autoconsumo).length > 0 &&
          'documentos_autoconsumo' in this.puntoSuministro.autoconsumo
        ) {
          if (this.puntoSuministro.autoconsumo.documentos_autoconsumo.length > 0) {
            this.puntoSuministro.autoconsumo.documentos_autoconsumo.forEach(fichero => {
              formData.append('documentacion_autoconsumo[]', fichero);
            });
          }
        }

        if (this.puntoSuministro.oldDocumentacionAutoconsumo.length > 0) {
          this.puntoSuministro.oldDocumentacionAutoconsumo.forEach(anexos => {
            formData.append('old_documentacion_autoconsumo[]', JSON.stringify(anexos));
          });
        }

        this.$emit('continue', formData);
      }
    },
    changeFeeEnergyDisabled(id, state) {
      if (!(id in this.disabledFeeEnergy)) this.disabledFeeEnergy[id] = false;
      this.disabledFeeEnergy[id] = state;
    },
    changeFeePotencia(id, state) {
      if (!(id in this.disabledFeePotencia)) this.disabledFeePotencia[id] = false;
      this.disabledFeePotencia[id] = state;
    },
    changeFeeAutoconsumo(id, state) {
      if (!(id in this.disabledFeeAutoconsumo)) this.disabledFeeAutoconsumo[id] = false;
      this.disabledFeeAutoconsumo[id] = state;
    },
    validateHiredPower() {
      this.problem = '';
      let idWithError = '';
      // eslint-disable-next-line no-unused-vars
      let pWithError = '';

      // eslint-disable-next-line no-unused-vars
      const { tarifaAtr } = this.tarifaAtrUsuario;
      // eslint-disable-next-line consistent-return
      this.myRates.forEach(rate => {
        if (!rate.selected) return true;

        // Tarifas 2.0 individuales
        if (rate.tarifaAtr === '018') {
          if (
            this.$refs[`slider_${rate.id}_p1`][0].$refs.potencia.checkInvalid().status &&
            idWithError === ''
          ) {
            idWithError = rate.id;
            pWithError = 1;
            this.problem = `${rate.alias}: El valor de la potencia contratada debe ser mayor a 0 kW y menor a 15 kW`;
          }
          if (
            this.$refs[`slider_${rate.id}_p2`][0].$refs.potencia.checkInvalid().status &&
            idWithError === ''
          ) {
            idWithError = rate.id;
            pWithError = 2;
            this.problem = `${rate.alias}: El valor de la potencia contratada debe ser mayor a 0 kW y menor a 15 kW`;
          }

          if (
            this.$refs[`slider_${rate.id}_p1`][0].$refs.potencia.value > 15 ||
            this.$refs[`slider_${rate.id}_p1`][0].$refs.potencia.value <= 0
          ) {
            idWithError = rate.id;
            pWithError = 1;
            this.problem = `${rate.alias}: El valor de la potencia contratada debe ser mayor a 0 kW y menor a 15 kW`;
            this.$refs[`slider_${rate.id}_p1`][0].$refs.potencia.invalidStored = true;
          }

          if (
            this.$refs[`slider_${rate.id}_p2`][0].$refs.potencia.value > 15 ||
            this.$refs[`slider_${rate.id}_p2`][0].$refs.potencia.value <= 0
          ) {
            idWithError = rate.id;
            pWithError = 3;
            this.problem = `${rate.alias}: El valor de la potencia contratada debe ser mayor a 0 kW y menor a 15 kW`;
            this.$refs[`slider_${rate.id}_p2`][0].$refs.potencia.invalidStored = true;
          }
        } else {
          const allPotencias = [];

          // eslint-disable-next-line no-plusplus
          for (let i = 1; i < 7; i++) {
            if (
              this.$refs[`slider_${rate.id}_p${i}`][0].$refs.potencia.checkInvalid().status &&
              idWithError === ''
            ) {
              idWithError = rate.id;
              pWithError = i;
              this.problem = 'Debe rellenar los valores de las potencias';
            }

            allPotencias.push({
              valor: this.$refs[`slider_${rate.id}_p${i}`][0].$refs.potencia.value,
              idWithError: rate.id,
              pWithError: i
            });
          }

          if (rate.tarifaAtr === '019' && !allPotencias.some(element => element.valor > 15)) {
            this.problem = `${rate.alias}: Alguna de las potencias debe ser mayor a 15 kW`;
            idWithError = allPotencias[0].idWithError;
            pWithError = allPotencias[0].pWithError;
          }

          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < allPotencias.length - 1; i++) {
            if (allPotencias[i].valor > allPotencias[i + 1].valor) {
              idWithError = allPotencias[i].idWithError;
              pWithError = allPotencias[i].pWithError;
              this.problem = `${rate.alias}: Las potencias deben ser en orden ascendente`;
            }
          }
        }
      });

      if (idWithError !== '') {
        this.myRates.forEach(rate => {
          // eslint-disable-next-line no-param-reassign
          rate.expandible = false;
          this.openMargenPotencia = false;
        });
        this.$nextTick(() => {
          this.myRates.find(rate => rate.id === idWithError).expandible = true;
          this.$nextTick(() => {
            this.openMargenPotencia = true;
            this.$nextTick(() => {
              setTimeout(() => {
                this.$el.getElementsByClassName('invalid')[0].scrollIntoView();
              }, 400);
            });
          });
        });
      }

      if (this.problem !== '') return false;
      return true;
    },
    expandRate(rate) {
      this.openFeeEenrgia = false;
      this.openMargenPotencia = false;
      this.openFeeAutoconsumo = false;
      this.openBateriaVirtalTarifa = false;
      this.myRates.forEach((element, index) => {
        if (element.id !== rate.id) this.myRates[index].expandible = false;
      });
      this.$nextTick(() => {
        this.myRates.find(element => element.id === rate.id).expandible = !rate.expandible;
      });
    },
    selectRate(rate) {
      // eslint-disable-next-line no-param-reassign
      rate.selected = !rate.selected;
    },
    selectAllRates() {
      return this.allRatesSelected
        ? this.changeAllRatesState(false)
        : this.changeAllRatesState(true);
    },
    changeAllRatesState(state) {
      this.myRates.forEach(rate => {
        if (rate.tarifaAtr === this.tarifaSelect || this.tarifaSelect === 'all')
          // eslint-disable-next-line no-param-reassign
          rate.selected = state;
      });
    },
    async getStaticData() {
      try {
        const response = await api.getFormInitialData();
        this.staticData.optionsCNAE = response.data.cnaes.map(cnae => {
          return {
            value: cnae.id,
            text: `${cnae.codigo} - ${cnae.descripcion}`
          };
        });
        this.loaded = true;
      } catch (exception) {
        this.loaded = true;
        this.$notify.error(
          'Problema con la llamada a la api, tablas maestras, para obtener toda la informacion de los selects'
        );
      }
    },
    changePuntoSuministro(data) {
      this.puntoSuministro = data;
    }
  }
};
