//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import dayjs from 'dayjs';
import ViuHeader from 'viu/components/viu-header/viu-header.vue';
import ViuLabel from 'viu/components/viu-label/viu-label.vue';
import ViuInput from 'viu/components/viu-input/viu-input.vue';
import ViuSelect from 'viu/components/viu-select/viu-select.vue';
import ViuButton from 'viu/components/viu-button/viu-button.vue';
import ViuUpload from 'viu/components/viu-upload/viu-upload.vue';
import ViuModal from 'viu/components/viu-modal/viu-modal.vue';
import ViuCalendar from 'viu/components/viu-calendar/viu-calendar.vue';
import ViuSwitch from 'viu/components/viu-switch/viu-switch.vue';
import validateForm from '$lib/validate-form.js';
import NifValidatorMixin from '$mixins/nif-validator-mixin.js';
import './style/puntos-suministros-formulario-style.scss';

import AddAutoconsumoSuministro from './add-autoconsumo-suministro.vue';
import AddBateriaVirtual from './add-bateria-virtual.vue';
import DocumentosAutoconsumo from './documentos-autoconsumo.vue';

import { getFormInitialData } from '../lib/api.js';

import * as api from '$network/api.js';

const extractOptions = (list, labelKey, valueKey) => {
  return list.map(i => ({ text: i[labelKey], value: i[valueKey] }));
};

export default {
  name: 'PuntosSuministrosFormulario',
  components: {
    ViuHeader,
    ViuLabel,
    ViuInput,
    ViuSelect,
    ViuButton,
    ViuUpload,
    ViuModal,
    ViuCalendar,
    ViuSwitch,
    AddAutoconsumoSuministro,
    AddBateriaVirtual,
    DocumentosAutoconsumo
  },
  mixins: [NifValidatorMixin],
  props: {
    allSteps: { type: Object },
    staticData: { type: Object },
    leadId: { type: String },
    houseType: String,
    editarConsumoAnualEstimado: Boolean,
    scoringActivo: Boolean,
    empresa: Boolean
  },
  data() {
    return {
      modalAddAutoconsumo: false,
      loading: false,
      modalAddBateriaVirtual: false,
      modalVerDocumentosAutoconsumo: false,
      documentOptions: [],
      optionsComercializadora: [
        { value: 'si', text: 'Si' },
        { value: 'no', text: 'No' },
        { value: 'no lo se', text: 'No lo se' }
      ],
      optionsProrrogaTacita: [
        { value: 'S', text: 'Si' },
        { value: 'N', text: 'No' }
      ],
      optionsTipoPersona: [
        { value: 'F', text: 'Física' },
        { value: 'J', text: 'Jurídica' }
      ],
      optionsAutonomo: [
        { value: 'S', text: 'Si' },
        { value: 'N', text: 'No' }
      ],
      userDocumentTypes: [],
      nifChecked: false,
      tipo_persona_residencia: this.allSteps[1].inputData.house_type === 'business' ? 'J' : 'F',
      today: dayjs().format('YYYY-MM-DD'),
      dataToSend: {
        fecha_nacimiento: '',
        autonomo: 'N',
        cups: '',
        tipo_persona: 'F',
        tipo_identificador: '',
        nombre_titular: '',
        primer_apellido_titular: '',
        segundo_apellido_titular: '',
        razon_social: '',
        identificador: '',
        dado_alta_esta_comer: '',
        mismo_titular: '',
        tarifa_actual: '',
        misma_potencia: '',
        CodigoPostal: '',
        comercializadora_actual: '',
        consumo_anual_estimado: '',
        tipo_gestion: 'cambio_comercializadora',
        CNAE: '',
        idCnae: '',
        autoconsumo: {},
        newDocumentacionAutoconsumo: [],
        oldDocumentacionAutoconsumo: [],
        bateriaVirtual: {},
        tipo_fianza: '',
        importe_fianza: '',
        inicio_contrato: '',
        fecha_inicio_contrato: '',
        prorroga_tacita: 'S',
        fichero: [],
        newFichero: [],
        oldFichero: [],
        anexos: [],
        newAnexos: [],
        oldAnexos: []
      }
    };
  },
  computed: {
    userDocumentTypesComputed() {
      const { userDocumentTypes } = this;

      // Los tipos que se muestran si es tipo empresa
      if (this.allSteps[1].inputData.house_type === 'business') {
        return userDocumentTypes.filter(d => ['CIF'].includes(d.text));
      }

      return userDocumentTypes.filter(d => !['CIF'].includes(d.text));
    },
    computedErrorPattern() {
      if (
        this.dataToSend.tipo_identificador === 4 &&
        this.dataToSend.identificador.length > 7 &&
        this.dataToSend.identificador.length < 10
      ) {
        return 'NIF incorrecto';
      }
      return 'Formato incorrecto';
    },
    documentAutoconsumoTotal() {
      if (this.dataToSend.oldDocumentacionAutoconsumo.length > 0)
        return this.dataToSend.oldDocumentacionAutoconsumo.length;
      if (this.dataToSend.autoconsumo.documentos_autoconsumo.length > 0)
        return this.dataToSend.autoconsumo.documentos_autoconsumo.length;
      return 0;
    },
    computedPattern() {
      let regexp = /[\s\S]*/;
      if (
        this.dataToSend.tipo_identificador === 'PS' ||
        this.dataToSend.tipo_identificador === 6 ||
        this.dataToSend.tipo_identificador === ''
      ) {
        return regexp;
      }
      if (this.dataToSend.tipo_identificador === 4 && this.dataToSend.tipo_persona === 'J') {
        regexp = /^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$/;
        return regexp;
      }
      if (this.dataToSend.tipo_identificador === 4 && this.dataToSend.tipo_persona === 'F') {
        if (['l', 'L', 'k', 'K', 'm', 'M'].includes(this.dataToSend.identificador[0])) {
          regexp = /^[LlKkMm]{1}[0-9]{7}[a-zA-Z]{1}$/;
          return regexp;
        }

        regexp = /^\d{8}[a-zA-Z]{1}$/;
        return regexp;
      }
      if (this.dataToSend.tipo_identificador === 8 && this.dataToSend.tipo_persona === 'F') {
        regexp = /^[XxTtYyZz]{1}([0-9]{7}|[0-9]{8})[a-zA-Z]{1}$/;
        return regexp;
      }
      if (this.dataToSend.tipo_identificador === 'NV') {
        regexp = /^[a-zA-Z]{2}[a-zA-Z0-9]{1,12}$/;
        return regexp;
      }
      return regexp;
    }
  },
  watch: {
    dataToSend: {
      deep: true,
      handler() {
        this.$emit('change', this.dataToSend);
      }
    },
    'dataToSend.CNAE': {
      deep: true,
      handler(value) {
        if (value === '') return;
        this.staticData.optionsCNAE.forEach(element => {
          if (Number(element.value) === Number(this.dataToSend.CNAE)) {
            // eslint-disable-next-line prefer-destructuring
            this.dataToSend.idCnae = element.text.split(' - ')[0];
          }
        });
      }
    },
    'dataToSend.autoconsumo': {
      deep: true,
      handler(value) {
        if (Object.keys(value).length === 0) {
          this.dataToSend.newDocumentacionAutoconsumo = [];
          return;
        }
        this.dataToSend.newDocumentacionAutoconsumo = value.documentos_autoconsumo;
      }
    },
    'dataToSend.fichero': {
      deep: true,
      handler(value) {
        if (Object.keys(value).length === 0) return;
        this.dataToSend.newFichero = value;
      }
    },
    'dataToSend.anexos': {
      deep: true,
      handler(value) {
        if (Object.keys(value).length === 0) return;
        this.dataToSend.newAnexos = value;
      }
    },
    'dataToSend.identificador': {
      handler(valor) {
        if (this.allSteps[1].inputData.house_type === 'business') {
          this.dataToSend.tipo_persona = 'J';
          return;
        }
        // Si el identificador contiene alguna minúcula, automáticamente se cambia a mayúsculas
        if (/[a-z]/.test(valor)) {
          this.dataToSend.identificador = this.dataToSend.identificador.toUpperCase();
        }
        if (this.dataToSend.tipo_identificador === 4 && this.dataToSend.identificador === '') {
          this.dataToSend.tipo_persona = 'F';
        }
        if (
          this.dataToSend.tipo_identificador === 8 ||
          (this.dataToSend.tipo_identificador === 4 &&
            /[0-9]/.test(this.dataToSend.identificador[0])) ||
          (this.dataToSend.tipo_identificador === 4 &&
            ['l', 'L', 'k', 'K', 'm', 'M'].includes(this.dataToSend.identificador[0]) &&
            /[a-zA-Z]/.test(this.dataToSend.identificador[8])) ||
          this.dataToSend.tipo_identificador === 'PS'
        ) {
          this.dataToSend.tipo_persona = 'F';
        }

        if (
          /[a-zA-Z]/.test(this.dataToSend.identificador[0]) &&
          this.dataToSend.tipo_identificador === 4 &&
          this.dataToSend.identificador !== ''
        ) {
          this.dataToSend.tipo_persona = 'J';
        }

        if (
          this.dataToSend.tipo_identificador === 'NV' ||
          this.dataToSend.tipo_identificador === 'OT'
        ) {
          this.dataToSend.tipo_persona = 'J';
        }

        this.checkNIFIdentifier();
      }
    },
    'dataToSend.tipo_identificador': {
      handler() {
        if (this.dataToSend.tipo_identificador === 4 && this.dataToSend.identificador === '') {
          this.dataToSend.tipo_persona = 'F';
        }

        if (this.allSteps[1].inputData.house_type === 'business') {
          this.dataToSend.tipo_persona = 'J';
        }

        if (
          this.dataToSend.tipo_identificador === 8 ||
          (this.dataToSend.tipo_identificador === 4 &&
            ['l', 'L', 'k', 'K', 'm', 'M'].includes(this.dataToSend.identificador[0]) &&
            /[a-zA-Z]/.test(this.dataToSend.identificador[8])) ||
          (this.dataToSend.tipo_identificador === 4 &&
            /[0-9]/.test(this.dataToSend.identificador[0])) ||
          this.dataToSend.tipo_identificador === 'PS'
        ) {
          this.dataToSend.tipo_persona = 'F';
        }
        if (
          this.dataToSend.tipo_identificador === 4 &&
          /[a-zA-Z]/.test(this.dataToSend.identificador[0]) &&
          this.dataToSend.identificador !== ''
        ) {
          this.dataToSend.tipo_persona = 'J';
        }
        if (
          this.dataToSend.tipo_identificador === 'NV' ||
          this.dataToSend.tipo_identificador === 'OT'
        ) {
          this.dataToSend.tipo_persona = 'J';
        }

        this.checkNIFIdentifier();
      }
    }
  },
  async created() {
    this.loading = true;
    const response = await getFormInitialData();
    this.userDocumentTypes = extractOptions(
      response.data.user_document_types.reduce(this.modifyUserOptions, []),
      'descripcion',
      'id'
    );

    await this.setData();
    this.loading = false;
  },
  methods: {
    modifyUserOptions(acc, option) {
      if (option.descripcion === 'NIF') {
        acc.push({ ...option, descripcion: 'DNI' });
        acc.push({ ...option, descripcion: 'CIF' });
      } else {
        acc.push(option);
      }

      return acc;
    },
    checkNIFIdentifier() {
      this.nifChecked = false;
      if (
        this.dataToSend.tipo_identificador === 4 &&
        (this.dataToSend.identificador.length === 8 ||
          this.dataToSend.identificador.length === 9) &&
        (/^[a-zA-Z]{1}\d{7}[a-zA-Z0-9]{1}$/.test(this.dataToSend.identificador) ||
          /^\d{8}[a-zA-Z]{1}$/.test(this.dataToSend.identificador))
      ) {
        const validNIF = this.NIFValidator(this.dataToSend.identificador);
        if (!validNIF) {
          this.nifChecked = true;
        }
      }
    },
    descargarFichero(filename) {
      api
        .downloadDocuments(this.leadId, filename)
        .then(response => {
          const href = URL.createObjectURL(response.data);

          const link = document.createElement('a');
          link.href = href;
          link.setAttribute(
            'download',
            response.headers['content-disposition'].split('filename=')[1]
          );
          document.body.appendChild(link);
          link.click();

          document.body.removeChild(link);
          URL.revokeObjectURL(href);
        })
        .catch(() => {
          this.$notify.error('No se ha podido descargar el informe');
        });
    },
    back() {
      this.$emit('back');
    },
    closeModalAutoconsumo() {
      this.modalAddAutoconsumo = false;
    },
    addAutoconsumo() {
      this.modalAddAutoconsumo = true;
    },
    closeModalBateriaVirtual() {
      this.modalAddBateriaVirtual = false;
    },
    addBateriaVirtual() {
      this.modalAddBateriaVirtual = true;
    },
    changeDatosAutoconsumo(value) {
      this.dataToSend.autoconsumo = value;
    },
    deleteAutoconsumo() {
      this.dataToSend.oldDocumentacionAutoconsumo = [];
      this.dataToSend.autoconsumo = {};
    },
    closeModalDocumentosAutoconsumo() {
      this.modalVerDocumentosAutoconsumo = false;
    },
    changeBateriaVirtual(value) {
      this.dataToSend.bateriaVirtual = JSON.parse(JSON.stringify(value));
    },
    deleteBateriaVirtual() {
      this.dataToSend.bateriaVirtual = {};
    },
    async setData() {
      if (this.allSteps['2'].inputData.type !== 'pdf') {
        this.dataToSend.cups = this.checkUndefined(this.allSteps['2'].inputData.data);
        this.dataToSend.CodigoPostal = this.checkUndefined(
          this.allSteps['2'].externalData.codPostal
        );
        this.dataToSend.tarifa_actual = this.checkUndefined(this.allSteps['2'].externalData.tarifa);
        this.dataToSend.consumo_anual_estimado =
          this.checkUndefined(this.allSteps['2'].externalData.consumoAnual) === ''
            ? ''
            : this.allSteps['2'].externalData.consumoAnual / 1000;

        if (this.allSteps['2'].externalData.idCnae !== '') {
          this.dataToSend.CNAE =
            this.checkUndefined(this.allSteps['2'].externalData.idCnae) !== ''
              ? Number(this.allSteps['2'].externalData.idCnae)
              : '';
        }

        if (
          (this.allSteps['2'].externalData.idCnae === '' ||
            !('idCnae' in this.allSteps['2'].externalData)) &&
          this.allSteps['1'].inputData.house_type === 'first_residence'
        ) {
          this.dataToSend.CNAE = 628;
        }

        if (
          (this.allSteps['2'].externalData.idCnae === '' ||
            !('idCnae' in this.allSteps['2'].externalData)) &&
          this.allSteps['1'].inputData.house_type === 'second_residence'
        ) {
          this.dataToSend.CNAE = 629;
        }

        if (this.checkUndefined(this.allSteps[2].externalData.codigoAutoconsumo) !== '') {
          this.dataToSend.autoconsumo = {
            tipo_autoconsumo_select: this.checkUndefined(
              this.allSteps[2].externalData.codigoAutoconsumo
            )
          };
        }
      } else {
        this.dataToSend.cups = this.checkUndefined(this.allSteps['2'].inputData.data);
        this.dataToSend.tarifa_actual = this.checkUndefined(
          this.allSteps['2'].inputData.invoice.informacionContratacion.tarifaLuz
        );
        this.dataToSend.comercializadora_actual = this.checkUndefined(
          this.allSteps['2'].inputData.invoice.nombreProveedor
        );
      }

      if (this.allSteps[4].finished) {
        this.dataToSend.autonomo =
          // eslint-disable-next-line no-nested-ternary
          this.checkUndefined(this.allSteps[4].inputData.autonomo) === ''
            ? 'N'
            : this.allSteps[4].inputData.autonomo === true
            ? 'S'
            : 'N';
        this.dataToSend.fecha_nacimiento = this.checkUndefined(
          this.allSteps[4].inputData.fechaNacimiento
        );
        this.dataToSend.dado_alta_esta_comer = this.checkUndefined(
          this.allSteps[4].inputData.dadoAltaEstaComer
        );

        this.dataToSend.mismo_titular = this.checkUndefined(
          this.allSteps[4].inputData.mismoTitular
        );

        this.dataToSend.misma_potencia = this.checkUndefined(
          this.allSteps[4].inputData.mismaPotencia
        );

        /* this.dataToSend.prorroga_tacita =
          // eslint-disable-next-line no-nested-ternary
          this.checkUndefined(this.allSteps[4].inputData.prorrogaTacita) === ''
            ? 'N'
            : this.allSteps[4].inputData.prorrogaTacita
            ? 'S'
            : 'N'; */

        this.dataToSend.CNAE =
          this.checkUndefined(this.allSteps[4].inputData.codigoCnae) !== ''
            ? Number(this.allSteps[4].inputData.codigoCnae)
            : '';
        this.dataToSend.consumo_anual_estimado = this.checkUndefined(
          this.allSteps[4].inputData.consumoAnualEstimado
        );

        if (
          this.checkUndefined(
            this.allSteps[4].inputData.autoconsumo.compensacion_por_excedentes
          ) !== '' ||
          this.checkUndefined(this.allSteps[4].inputData.autoconsumo.excedentes) !== '' ||
          this.checkUndefined(this.allSteps[4].inputData.autoconsumo.tipo_autoconsumo_select) !== ''
        )
          this.dataToSend.autoconsumo = this.checkUndefined(this.allSteps[4].inputData.autoconsumo);

        if (
          'documentos' in this.allSteps[4].inputData.autoconsumo &&
          this.allSteps[4].inputData.autoconsumo?.documentos.length !== 0
        ) {
          this.dataToSend.autoconsumo.documentos_autoconsumo = this.checkUndefined(
            this.allSteps[4].inputData.autoconsumo.documentos
          );
        }

        this.dataToSend.oldDocumentacionAutoconsumo = this.allSteps[4].inputData.documentacionAutoconsumo.map(
          element => {
            return JSON.parse(element);
          }
        );

        this.dataToSend.oldFichero = this.allSteps[4].inputData.documentacionAdicional.map(
          element => {
            return JSON.parse(element);
          }
        );

        this.dataToSend.oldAnexos = this.allSteps[4].inputData.documentacionAnexos.map(element => {
          return JSON.parse(element);
        });

        if (
          this.allSteps[4].inputData.bateriaVirtual !== undefined &&
          (this.checkUndefined(this.allSteps[4].inputData.bateriaVirtual.codigo_bateria_virtual) !==
            '' ||
            this.checkUndefined(
              this.allSteps[4].inputData.bateriaVirtual.identificador_asociado
            ) !== '' ||
            this.checkUndefined(this.allSteps[4].inputData.bateriaVirtual.modo_bateria) !== '')
        ) {
          this.dataToSend.bateriaVirtual.codigo_bateria_virtual = this.checkUndefined(
            this.allSteps[4].inputData.bateriaVirtual.codigo_bateria_virtual
          );
          this.dataToSend.bateriaVirtual.identificador_asociado = this.checkUndefined(
            this.allSteps[4].inputData.bateriaVirtual.identificador_asociado
          );
          this.dataToSend.bateriaVirtual.modo_bateria =
            // eslint-disable-next-line no-nested-ternary
            this.checkUndefined(this.allSteps[4].inputData.bateriaVirtual.modo_bateria) === ''
              ? ''
              : this.allSteps[4].inputData.bateriaVirtual.modo_bateria === 'unica'
              ? 'U'
              : 'M';
        }

        this.dataToSend.tipo_fianza =
          this.checkUndefined(this.allSteps[4].inputData.garantia.tipo_garantia) !== ''
            ? Number(this.allSteps[4].inputData.garantia.tipo_garantia)
            : '';
        this.dataToSend.importe_fianza = this.checkUndefined(
          this.allSteps[4].inputData.garantia.importe_garantia
        );
        this.dataToSend.inicio_contrato = this.checkUndefined(
          this.allSteps[4].inputData.inicioContrato
        );
        this.dataToSend.fecha_inicio_contrato = this.checkUndefined(
          this.allSteps[4].inputData.fechaInicioContrato
        );

        this.dataToSend.tipo_identificador =
          this.checkUndefined(this.allSteps[4].inputData.datosTitular.tipoIdentificador) !== ''
            ? Number(this.allSteps[4].inputData.datosTitular.tipoIdentificador)
            : '';

        this.dataToSend.identificador = this.checkUndefined(
          this.allSteps[4].inputData.datosTitular.identificador
        );

        this.dataToSend.tipo_persona = this.checkUndefined(
          this.allSteps[4].inputData.datosTitular.tipoPersona
        );

        this.dataToSend.nombre_titular = this.checkUndefined(
          this.allSteps[4].inputData.datosTitular.nombre
        );

        this.dataToSend.primer_apellido_titular = this.checkUndefined(
          this.allSteps[4].inputData.datosTitular.primerApellido
        );

        this.dataToSend.segundo_apellido_titular = this.checkUndefined(
          this.allSteps[4].inputData.datosTitular.segundoApellido
        );

        this.dataToSend.razon_social = this.checkUndefined(
          this.allSteps[4].inputData.datosTitular.razonSocial
        );
        if (this.allSteps['4'].inputData.selectedRates.length > 0) {
          this.$nextTick(() => {
            this.$emit('calcularScoring');
          });
        }
      }
    },
    checkUndefined(texto) {
      if (texto !== undefined && texto !== null) return texto;
      return '';
    },
    validate() {
      return validateForm.validate(this, []);
    },
    eliminarDocument(filename) {
      this.dataToSend.oldFichero = this.dataToSend.oldFichero.filter(
        file => file.filename !== filename
      );
    },
    eliminarAnexo(filename) {
      this.dataToSend.oldAnexos = this.dataToSend.oldAnexos.filter(
        file => file.filename !== filename
      );
    }
  }
};
